import { gsap } from 'gsap';
import icons from '../static/res/svg/*.svg';
import {prepareAssetPath} from "../../../../../utilities";

export default class BaseTile {
	constructor(data, ctx, legacyMode) {
		this.painted = false;
		this.ready = false;
		this.debug = false;
		this.legacyMode = legacyMode;
		this.previewCtx = ctx;
		this.data = JSON.parse(JSON.stringify(data)); //clone data
		this.animOffset = { x: 0, y: 0 };
		this.startPos = { x: 0, y: 0 };
		this.endPos = { x: 0, y: 0 };
		this.debugmsg = '';
		// this.duration = (2 / this.data.speed) * 8;
	}

	calculateDuration() {
		let length = 0;

		// grab the width of screen if anim is vertical.
		// length = this.isHorizAnim ? Math.ceil(this.drawContent()) : this.data.width;
		length = this.isHorizAnim ? Math.ceil(this.drawContent()) : this.data.width;

		// length = length * devicePixelRatio;

		// this.duration = (Math.pow(length / 100), 1.05) / (this.data.speed * 0.5);
		// get the length of the content
		// get the length of the screen
		// check if high pixel density and divide by 2 if needed

		// 100px per second
		const pxPerSecond = this.isHorizAnim ? 75 : 500;
		const multiplier = this.data.speed;
		this.duration = length / (pxPerSecond * multiplier);
	}

	setLoopingConfig() {
		if (this.isStatic()) {
			this.startPos.y = 0;
			this.endPos.y = 0;
			return;
		}

		switch (this.data.direction) {
			case 'left':
				this.startPos.x = 0;
				this.endPos.x = -this.contentWidth;
				break;
			case 'right':
				this.startPos.x = -this.contentWidth;
				this.endPos.x = 0;
				break;
			case 'up':
				this.startPos.y = 0;
				this.endPos.y = -this.data.height;
				break;
			case 'down':
				this.startPos.y = -this.data.height;
				this.endPos.y = 0;
				break;
		}
	}

	initCanvas() {
		this.cnv = document.createElement('canvas');
		this.ctx = this.cnv.getContext('2d');
		this.cnv.width = this.data.width;
		this.cnv.height = this.data.height;
	}

	/**
	 * Sets up GSAP Tween.
	 */
	setupTween() {
		this.twn = gsap.fromTo(this.animOffset, this.startPos, {
			...this.endPos,
			ease: 'none',
			duration: this.duration,
			repeat: -1,
			paused: !this.legacyMode,
			immediateRender: !this.legacyMode,
		});
	}

	/**
	 * Draws the tile multiple times to fill the tile horizontally or vertically, depending on the orientation.
	 * Uses internal variables.
	 * @note must have variables / methods implemented.
	 */
	drawTile(horizontalIncrementer = 1, verticalCount = 2) {
		// draw once to measure content width
		this.contentWidth = Math.ceil(this.drawContent());

		// one extra for horizontal tiling
		let contentCount = Math.ceil(this.data.width / this.contentWidth);

		let vertCount = 1;
		if (this.isHorizAnim) {
			contentCount += horizontalIncrementer;
		} else {
			this.cnv.height = this.data.height * 2;
			vertCount = verticalCount;
		}

		this.cnv.width = this.contentWidth * contentCount;

		// draw background color
		if (this.data.backColor === 'betrue') {
			this.renderBeTrueColorBackground();
		} else {
			this.ctx.fillStyle = this.data.backColor ?? '#000000';
			this.ctx.fillRect(0, 0, this.cnv.width, this.cnv.height);
		}

		this.drawSerial(vertCount, contentCount);
	}

	renderBeTrueColorBackground() {
		const BETRUE_COLORS = ['#C0228F', '#C8002D', '#E15200', '#F2CF4F', '#398245', '#0089A9', '#00369C', '#753695'];
		const height = this.cnv.height / BETRUE_COLORS.length;

		BETRUE_COLORS.forEach((color, index) => {
			this.ctx.fillStyle = color;
			this.ctx.fillRect(0, index * height, this.cnv.width, (index + 1) * height)
		})
	}

	/**
	 * Draws the content based on the spec.
	 * @note: must implement method drawContent();
	 */
	drawSerial(vertCount, contentCount) {
		if (this.isStatic()) {
			vertCount = 1;
			contentCount = 1;
		}

		for (let j = 0; j < vertCount; j++) {
			let offsetHeight = j * this.data.height;

			for (let i = 0; i < contentCount; i++) {
				let offsetWidth = this.contentWidth * i;

				if (this.isStatic()) {
					// center the content, by moving content by half of the remaining
					offsetWidth = (this.data.width - this.contentWidth) / 2;
				}

				this.drawContent({ x: offsetWidth, y: offsetHeight }, i);
			}
		}
	}

	isStatic() {
		return this.data.speed == 0;
	}

	/**
	 * Loads an array of images and when all are loaded flips the ready flag.
	 */
	loadImages() {
		return new Promise((resolve) => {
			let prms = [];

			this.data.content.forEach((obj, index) => {
				if (obj.type === 'image') prms.push(this.loadImage(obj, index));
			});

			Promise.all(prms).then((values) => {
				values.forEach((value) => {
					if (value && value.hasOwnProperty('failed') && !isNaN(value.failed)) {
						this.data.content = this.data.content.filter((val, index) => {
							return index != value.failed
						})
					}

				});
				resolve();
				this.ready = true;
			})
		});
	}

	/**
	 * Load image to local cache. append img to passed in obj.
	 */
	loadImage(obj, index) {
		const file = obj.url.split('/').pop();
		const filename = icons[file.split('.')[0]];
		const path = prepareAssetPath(filename);

		return new Promise((resolve) => {
			fetch(path)
				.then((r) => {
					const contentType = r.headers.get('content-type');

					if (r.status == 404 || (contentType && contentType.indexOf('svg') == -1)) {
						// resolve right away when the image is not found.
						resolve({
							failed: index
						});
						return;
					}

					return r.text()
				})
				.then((svgText) => {
					svgText = this.processColors(svgText);

					let blob = new Blob([svgText], { type: 'image/svg+xml' });
					let url = URL.createObjectURL(blob);
					console.log('url', url)
					obj.img = new Image();
					obj.img.onload = () => resolve();
					obj.img.src = url;
				})
		});
	}

	processColors(svgRaw) {
		// convert black/currentColor
		return svgRaw
			.split('fill="black"')
			.join(`fill="${this.data.frontColor}"`)
			.split('stroke="black"')
			.join(`stroke="${this.data.frontColor}"`)
			.split('stroke="currentColor"')
			.join(`stroke="${this.data.frontColor}"`)
			.split('fill="currentColor"')
			.join(`fill="${this.data.frontColor}"`)
			.split('fill="white"')
			.join(`fill="${this.data.backColor}"`)
			.split('stroke="white"')
			.join(`stroke="${this.data.backColor}"`);

		// return (
		// 	svgRaw
		// 		.replaceAll('fill="black"', `fill="${this.data.frontColor}"`)
		// 		.replaceAll('stroke="black"', `stroke="${this.data.frontColor}"`)
		// 		.replaceAll('stroke="currentColor"', `stroke="${this.data.frontColor}"`)
		// 		.replaceAll('fill="currentColor"', `fill="${this.data.frontColor}"`)

		// 		// convert white
		// 		.replaceAll('fill="white"', `fill="${this.data.backColor}"`)
		// 		.replaceAll('stroke="white"', `stroke="${this.data.backColor}"`)
		// );
	}

	/**
	 * Shows debugging message if the debug flag is set.
	 */
	considerDebug() {
		if (!this.debug) {
			return;
		}

		this.previewCtx.strokeStyle = 'red';
		this.previewCtx.lineWidth = 1;
		this.previewCtx.stroke();

		this.previewCtx.fillStyle = 'red';
		this.previewCtx.font = '14px sans-serif';
		this.previewCtx.fillText(
			`${this.data.id}: pos: (${this.previewX}, ${this.previewY}), size: ${this.data.width} x ${this.data.height}`,
			this.previewX + 5,
			this.previewY + 18
		);
	}

	considerDebugOutline() {
		if (this.debug && this.contentWidth) {
			this.ctx.strokeStyle = 'red';
			this.ctx.lineWidth = 1;
			this.ctx.strokeRect(
				tileOffset.x,
				tileOffset.y,
				this.contentWidth,
				this.data.height
			);
		}
	}

	/**
	 * Clips outside of the tile. Kind of like overflow hidden.
	 */
	clipOverflow() {
		this.previewCtx.beginPath();
		this.previewCtx.rect(
			this.previewX,
			this.previewY,
			this.data.width,
			this.data.height
		);

		this.previewCtx.clip();
	}

	/**
	 * Draws the image based on the animation vars.
	 */
	drawImage() {
		this.previewCtx.drawImage(
			this.cnv,
			this.previewX + this.animOffset.x,
			this.previewY + this.animOffset.y,
			this.cnv.width,
			this.cnv.height
		);
	}

	flipInitialPaintFlag() {
		if (this.painted === false) {
			this.painted = true;
		}
	}
}
