/*

  Tile is a looping scrolling animation of content
  content can be any number of images,text and spacer aligned left to right
  Multiple tiles can be displayed on the preview screen

*/

import BaseTile from './BaseTile';

// percent of tile height to fill with content
let contentHeightPercent = 62;

// @TODO add metrics for extra fonts
let fontMetrics = {futura: {capHeight: 30, descHeight: 8}};
let FONT_OFFSET = {
	'noto-sans-sc': .14,
	'helvetica-neue-hoi': -.15,
	'palatino': -.08,
};

export class Tile extends BaseTile {
	constructor(data, ctx, legacyMode) {
		super(data, ctx, legacyMode)
		this.type = 'horizontal';

		this.previewX = this.data.x || 0;
		this.previewY = this.data.y || 0;

		this.isHorizAnim = this.data.direction === 'left' || this.data.direction === 'right';

		this.initCanvas();

		this.loadImages().then(() => {
			this.calculateDuration();
			this.drawTile();
			this.setLoopingConfig()
			this.setupTween();
		});
	}

	/**
	 * Draw content will render the the contents of the tile at x:0 y:0 or if otherwise specified.
	 */
	drawContent({x: xPos, y: yPos} = {x: 0, y: 0}, index = 0) {
		let capHeight = (contentHeightPercent / 100) * this.data.height;
		let fontSize = this.calculateFontSize(capHeight);
		// let yTop = Math.round((this.data.height - capHeight) / 2) + yPos;
		let yTop = ((this.data.height - capHeight) / 2) + yPos;

		this.data.content.forEach((item) => {
			switch (item.type) {
				case 'spacer':
					xPos += item.width;
					break;
				case 'text':
					xPos = this.renderText(item, fontSize, xPos, yTop);
					break;
				case 'image':
					xPos = this.renderImage(item, xPos, yTop, capHeight);
				default:
					break;
			}
		});

		this.considerDebugOutline();

		return xPos;
	}

	renderImage(item, xPos, yTop, capHeight) {
		const width = (item.img.width / item.img.height) * capHeight;
		this.ctx.drawImage(item.img, xPos, yTop, width, capHeight);
		return Math.ceil((item.img.width / item.img.height) * capHeight) + xPos;
	}

	renderText(item, fontSize, xPos, yTop) {
		this.ctx.fillStyle = this.data.frontColor ?? '#FFFFFF';
		this.ctx.font = `${fontSize}px ${item.font}`;
		this.ctx.textAlign = 'start';
		// this.ctx.textBaseline = 'top';

		// this is a hack for the BrightSign monitors
		// they are running a version of chromium where `textBaseLine = 'top'` is broken
		// using `hanging` with a correction.
		this.ctx.textBaseline = 'hanging';
		let percentageOffset = .02;

		if (FONT_OFFSET.hasOwnProperty(item.font)) {
			percentageOffset = FONT_OFFSET[item.font];
		}

		yTop = yTop - (this.data.height * percentageOffset)

		this.ctx.fillText(`${item.copy}${this.debugmsg}`, xPos, yTop);
		let w = Math.ceil(this.ctx.measureText(item.copy).width);
		xPos += w;
		return xPos;
	}

	calculateFontSize(capHeight) {
		return capHeight * (1 + fontMetrics.futura.descHeight / fontMetrics.futura.capHeight);
	}

	update() {
		this.previewCtx.save();
		this.clipOverflow();
		this.drawImage();
		this.considerDebug()
		this.previewCtx.restore();

		this.flipInitialPaintFlag();
	}
}
