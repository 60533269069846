/*

    Preview can display 1 or more Tiles
    reads in tileData

*/

import { Tile } from './Tile.js';
import { VerticalTile } from './VerticalTile.js';

export class Preview {
  constructor(tileData, ctx, width, height) {
    this.legacyMode = false;
    // init tiles
    this.ctx = ctx;
    this.width = width;
    this.height = height;
    this.tiles = [];
    this.running = false;

    //copy tiles data into collection array
    //allowing duplicate tiles with same id
    tileData.collection.forEach((tile) => {
      let anim = tileData.animations.find((anim) => anim.id === tile.id);
      Object.assign(tile, anim);
    });
    let mergedData = tileData.collection;

    // debug reps
    mergedData.forEach((datum) => {
      // support for legacy templates that use the theme literal.
      if (!datum?.frontColor || !datum?.backColor) {
        datum.frontColor = datum.theme === 'dark' ? '#FFFFFF' : '#000000';
        datum.backColor = datum.theme === 'dark' ? '#000000' : '#FFFFFF';
      }


      if (datum.orientation === 'vertical') {
        this.tiles.push(new VerticalTile(datum, ctx, this.legacyMode));
      } else {
        this.tiles.push(new Tile(datum, ctx, this.legacyMode));
      }
    });

    this.update = this.update.bind(this);

    if (this.legacyMode) {
      this.update()
    } else {
      const readyInterval = setInterval(() => {
        if (!this.tiles.some(tile => tile.ready === false)) {
          this.update();
          clearInterval(readyInterval);
        }
      }, 25);
    }

    setTimeout(() => {
      //calc shortest loop to loop all
      //TODO - use this value for video export duration
      const gcd = (a, b) => (a ? gcd(b % a, a) : b);
      const lcm = (a, b) => (a * b) / gcd(a, b);
      let durs = [];
      this.tiles.forEach((tile) => {
        durs.push(tile.duration);
      });

      console.log('loop lengths', durs, 'minimum loop length', durs.reduce(lcm));
    }, 1000);
  }

  run() {
    this.tiles.forEach(tile => {
			if (tile && tile.twn) {
				return tile.twn.restart()
			} else {
				console.error('Could not restart `tile`');
			}
		});
  }

  update() {
    requestAnimationFrame(this.update);
    this.ctx.fillStyle = '#FFFFFF';
    this.ctx.fillRect(0, 0, this.width, this.height);

    this.tiles.map((tile) => {
      tile.update();
    });

    if (this.legacyMode === false && this.running === false && !this.tiles.some(tile => tile.painted === false)) {
      this.run();
      this.running = true;
    }
  }

  setDebug(b) {
    this.tiles.forEach((tile) => {
      tile.debug = b;
    });
  }

  setPaused(b) {
    this.tiles.forEach((tile) => {
      tile.twn.paused(b);
    });
  }
}
