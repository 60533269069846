const _temp0 = require("./Frame-1.svg");
const _temp1 = require("./Frame.svg");
const _temp2 = require("./jumpman.svg");
const _temp3 = require("./swoosh.svg");
const _temp4 = require("./ticker_icon_bag.svg");
const _temp5 = require("./ticker_icon_brafit.svg");
const _temp6 = require("./ticker_icon_instant_checkout.svg");
const _temp7 = require("./ticker_icon_mtz.svg");
const _temp8 = require("./ticker_icon_nby_apparel.svg");
const _temp9 = require("./ticker_icon_nby_shoe.svg");
const _temp10 = require("./ticker_icon_nike_experiences.svg");
const _temp11 = require("./ticker_icon_nike_experts.svg");
const _temp12 = require("./ticker_icon_nike_smile.svg");
const _temp13 = require("./ticker_icon_nike_text.svg");
const _temp14 = require("./ticker_icon_nikeapp.svg");
const _temp15 = require("./ticker_icon_nrc.svg");
const _temp16 = require("./ticker_icon_ntc.svg");
const _temp17 = require("./ticker_icon_order_pickup.svg");
const _temp18 = require("./ticker_icon_paris_jumpman.svg");
const _temp19 = require("./ticker_icon_paris_jumpman_contained.svg");
const _temp20 = require("./ticker_icon_paris_jumpman_crescent.svg");
const _temp21 = require("./ticker_icon_paris_jumpman_emblem.svg");
const _temp22 = require("./ticker_icon_returns.svg");
const _temp23 = require("./ticker_icon_scan.svg");
const _temp24 = require("./ticker_icon_self_checkout.svg");
const _temp25 = require("./ticker_icon_shoefit.svg");
module.exports = {
  "Frame-1": _temp0,
  "Frame": _temp1,
  "jumpman": _temp2,
  "swoosh": _temp3,
  "ticker_icon_bag": _temp4,
  "ticker_icon_brafit": _temp5,
  "ticker_icon_instant_checkout": _temp6,
  "ticker_icon_mtz": _temp7,
  "ticker_icon_nby_apparel": _temp8,
  "ticker_icon_nby_shoe": _temp9,
  "ticker_icon_nike_experiences": _temp10,
  "ticker_icon_nike_experts": _temp11,
  "ticker_icon_nike_smile": _temp12,
  "ticker_icon_nike_text": _temp13,
  "ticker_icon_nikeapp": _temp14,
  "ticker_icon_nrc": _temp15,
  "ticker_icon_ntc": _temp16,
  "ticker_icon_order_pickup": _temp17,
  "ticker_icon_paris_jumpman": _temp18,
  "ticker_icon_paris_jumpman_contained": _temp19,
  "ticker_icon_paris_jumpman_crescent": _temp20,
  "ticker_icon_paris_jumpman_emblem": _temp21,
  "ticker_icon_returns": _temp22,
  "ticker_icon_scan": _temp23,
  "ticker_icon_self_checkout": _temp24,
  "ticker_icon_shoefit": _temp25
}