import { Preview } from './Preview.js';

class Ticker {
    constructor(options = { logger: console }) {
        const { logger } = options;

        this.logger = logger;
        if (!logger.debug) {
            this.logger.debug = logger.log;
        }

        this.element = null;
        this.canvas = null;
        this.context = null;
        this.preview = null;

        this.init = ({ divId, data }) => {
			document.fonts.ready.then(() => {
				// remove any memory reference
				const cloned = JSON.parse(JSON.stringify(data));

				this.logger.debug('WebGL.TickerAnimation.init() started', { _t: this, divId, data: cloned })

				const { displayWidth, displayHeight } = cloned;
				this.element = document.getElementById(divId);

				//create preview canvas
				this.canvas = document.createElement('canvas');
				this.canvas.width = displayWidth;
				this.canvas.height = displayHeight;
				this.context = this.canvas.getContext('2d');
				this.element.appendChild(this.canvas)

				this.preview = new Preview(cloned, this.context, displayWidth, displayHeight);

				this.logger.debug('WebGL.TickerAnimation.init() finished', { _t: this, divId, data });
			});
        }

        this.start = () => {
            this.logger.debug('WebGL.TickerAnimation.start()', { _t: this });
        }

        this.pause = () => {
            this.logger.debug('WebGL.TickerAnimation.pause()', { _t: this });
        }

        this.reset = () => {
            this.logger.debug('WebGL.TickerAnimation.reset()', { _t: this });
        }

        this.destroy = () => {
            this.logger.debug('WebGL.TickerAnimation.destroy()', { _t: this });
            if (this.canvas) {
                const { width, height } = this.canvas;
                if (this.context) {
                    this.context.clearRect(0, 0, width, height);
                }
                this.element.removeChild(this.canvas)

                // reset references
                this.element = null;
                this.canvas = null;
                this.context = null;
                this.preview = null;
            }
        }
    }

}

(() => { window.TickerAnimation = Ticker; })();
