/*

  Tile is a looping scrolling animation of content
  content can be any number of images,text and spacer aligned left to right
  Multiple tiles can be displayed on the preview screen

*/

import BaseTile from './BaseTile';

/**
 * Rotates the input direction.
 */
const DIRECTION_MAPPER = {
	// left: 'up',
  // right: 'down',
  left: 'down',
  right: 'up',
  up: 'right',
  down: 'left',
};

export class VerticalTile extends BaseTile {
  constructor(data, ctx, legacyMode) {
    super(data, ctx, legacyMode);
		this.type = 'vertical';

    // flip inputs
    this.previewX = this.data.y || 0;
    this.previewY = -this.data.x || 0;
    let tempw = this.data.width;
    this.data.width = this.data.height;
    this.data.height = tempw;

    this.data.direction = DIRECTION_MAPPER[this.data.direction];

    this.isHorizAnim = this.data.direction === 'left' || this.data.direction === 'right';

    this.initCanvas();

    this.loadImages(this.data.content).then(() => {
			this.calculateDuration();
      this.drawTile();
      this.setLoopingConfig();
      this.setupTween();
    });
  }

  /**
   * Draw content will render the the contents of the tile at x:0 y:0 or if otherwise specified.
   */
  drawContent({x: xPos, y: yPos} = { x: 0, y: 0 }, index = 0) {
    const fontSize = this.data.height * 0.8;
    let yTop = yPos;

    this.data.content.forEach((item) => {
      switch (item.type) {
        case 'spacer':
          xPos += item.width;
          break;
        case 'text':
          xPos = this.renderText(fontSize, item, xPos, yTop);
          break;
        case 'image':
          xPos = this.renderImage(item, xPos, yPos, yTop);
        default:
          break;
      }
    });

    this.considerDebugOutline();

    return xPos;
  }

  renderImage(item, xPos, yPos, yTop) {
    if (item.flip) {
      let h = this.data.height * 0.7;
      let w = (item.img.width / item.img.height) * h;
      const y = yPos + (this.data.height - h) / 2;
      this.ctx.drawImage(item.img, xPos, y, w, h);
      xPos += w;
    } else {
      let w = this.data.height * 0.7;
      let h = (item.img.height / item.img.width) * w;
      this.ctx.save();
      this.ctx.translate(xPos + h / 2, this.data.height / 2);
      this.ctx.rotate(-Math.PI / 2);
      this.ctx.drawImage(item.img, -yTop - (w / 2), -h / 2, w, h);
      this.ctx.restore();
      xPos += h;
    }

    return xPos;
  }

  renderText(fontSize, item, xPos, yTop) {
    this.ctx.fillStyle = this.data.frontColor ?? '#FFFFFF';
    this.ctx.font = `${fontSize}px ${item.font}`;
    this.ctx.textAlign = 'center';
    this.ctx.textBaseline = 'middle';

    xPos += this.data.height / 2;
    item.copy.split('').forEach((char) => {
      this.ctx.save();
      this.ctx.translate(xPos, yTop + (this.data.height / 2));
      this.ctx.rotate(-Math.PI / 2);
      xPos += this.data.height;
      this.ctx.fillText(char, 0, 0);
      this.ctx.restore();
    });
    xPos -= (this.data.height / 2);
    return xPos;
  }

  update() {
    this.previewCtx.save();

    // rotate around for the vertical tile.
    this.previewCtx.translate(this.data.height, 0);
    this.previewCtx.rotate(Math.PI / 2);

    this.clipOverflow();
    this.drawImage();
    this.considerDebug();
    this.previewCtx.restore();

    this.flipInitialPaintFlag();
  }
}
